<template>
  <div id="app">
    <MetaMaskButton @account-changed="handleAccountChanged"></MetaMaskButton>
  </div>
</template>

<script>
import MetaMaskButton from './components/MetaMaskButton.vue';

export default {
  name: 'App',
  components: {
    MetaMaskButton,
  },
  data() {
    return {
      account: null,
    };
  },
  methods: {
    handleAccountChanged(newAccount) {
      this.account = newAccount;
    }
  }
};
</script>

<style>
/* Global styles */
body{
  font-family: Arial, sans-serif; /* Ersetzen Sie dies durch Ihre bevorzugte Schriftart */
}
</style>
