<template>
  <button class="connect-button" @click="connectCoinbaseWallet()" :disabled="isConnected">
    {{ isConnected ? 'Coinbase Wallet' : 'Coinbase Wallet' }}
  </button>

  <button class="connect-button" @click="connectMetaMaskWallet()" :disabled="isConnected">
    {{ isConnected ? 'MetaMask Wallet' : 'MetaMask Wallet' }}
  </button>

  <div class="transfer-transaction">
    <h4>Transfer Tokens (transferFrom)</h4>
    <input v-model="transferFromAddress" placeholder="From address" />
    <input v-model="transferToAddress" placeholder="To address" />
    <input v-model="transferAmount" placeholder="Amount in Tokens" type="number" />
    <button @click="transferFromTokens()">Transfer</button>
  </div>
<!--
  <div class="wallet-info">
    <h4>Connected Wallet</h4>
    <p v-if="isConnected" class="wallet-address">{{ account.toString() }}</p>
    <p v-else class="none-indicator">none</p>

    <h4>Wallet ETH:</h4>
    <p v-if="isConnected" class="wallet-balance">{{ balanceEther }}</p>
    <p v-else class="none-indicator">none</p>
  </div>
-->
  <!--
  <div class="send-Transaction">
    <h4>Send ETH from Contract to recipient</h4>
    <input v-model="sendToAddress" placeholder="Address of the recipient" />
    <input v-model="sendAmount" placeholder="Amount in Ether" />
    <button @click="saendEth(sendToAddress, sendAmount)">send</button>
  </div>
  -->
<!--  <div>
    <input v-model="approveAddress" placeholder="Address of the recipient" />
    <button @click="approve(approveAddress)">approvement to recipient</button>
  </div> -->
  </template>

<script>
import MetaMaskService from '@/services/MetaMaskService';

export default {
  data() {
    return {
      isConnected: false,
      account: null,
      balanceEther: null,
      contract: null,
      sendToAddress: '',
      sendAmount: '',
      approveAddress: '',

      transferFromAddress: '',
      transferToAddress: '',
      transferAmount: '',
    };
  },
  methods: {

    async transferFromTokens() {
      if (!this.transferFromAddress || !this.transferToAddress || !this.transferAmount) {
        alert('Please fill in all fields');
        return;
      }
      try {
        console.log('TransferFrom...');
        await MetaMaskService.transferFromTo(
            this.transferFromAddress,
            this.transferToAddress,
            this.transferAmount,
            "100",
            50000
        );
        alert('Transfer was successful!');
      } catch (error) {
        console.error('TransferFrom failed:', error);
        alert('Transfer failed: ' + error.message);
      }
    },

    mounted() {
      this.initializeWeb3();
    },

    //Amount vorher abfragen. Testweise selbst reinschreiben
    async approve(address, amount) {
      try {
        console.log('approve...');
        await MetaMaskService.approve(address, amount)
      } catch (error) {
        console.error('approve failed:', error);
      }
    },

    async sendEth(toAddress, amount) {
      try {
        await MetaMaskService.sendTransaction(toAddress, amount);
      } catch (error) {
        console.error('Transaction failed:', error);
      }
    },

    async connectCoinbaseWallet() {
      //if (!MetaMaskService.isMetaMaskInstalled()) {
      //  alert('MetaMask not installed. Please install MetaMask.');
      // return;
      //}
      try {
        // Verbinden mit MetaMask und Informationen abrufen
        const {account, contract} = await MetaMaskService.connectToCoinbaseWallet();
        this.account = account; // Speichern der Account-Adresse
        this.balanceEther = null; // Speichern des Guthabens in Ether // checken
        this.contract = contract; // Speichern des Guthabens in Ether
        this.isConnected = true; // Aktualisieren des Verbindungsstatus
      } catch (error) {
        console.error('connecting failed with MetaMask:', error);
      }
    },

    async connectMetaMaskWallet() {
      //if (!MetaMaskService.isMetaMaskInstalled()) {
      //  alert('MetaMask not installed. Please install MetaMask.');
      // return;
      //}
      try {
        // Verbinden mit MetaMask und Informationen abrufen
        const {account, contract} = await MetaMaskService.connectToMetaMask();
        this.account = account; // Speichern der Account-Adresse
        this.balanceEther = null; // Speichern des Guthabens in Ether // checken
        this.contract = contract; // Speichern des Guthabens in Ether
        this.isConnected = true; // Aktualisieren des Verbindungsstatus
      } catch (error) {
        console.error('connecting failed with MetaMask:', error);
      }
    }
  }
}
</script>

<style scoped>

.transfer-transaction {
  max-width: 400px;
  margin: 20px auto;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.transfer-transaction input {
  width: 100%;
  padding: 10px;
  margin: 10px 0; /* Fügt zwischen den Eingabefeldern Abstand hinzu */
  border-radius: 5px;
  border: 1px solid #ccc; /* Leicht graue Border für die Inputs */
  box-sizing: border-box; /* Stellt sicher, dass Padding und Border die Breite der Inputs nicht verändern */
}

.transfer-transaction button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #4CAF50; /* Grün */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.transfer-transaction button:hover {
  background-color: #45a049;
}

.transfer-transaction h4 {
  text-align: center;
  margin-bottom: 20px; /* Fügt unter der Überschrift mehr Abstand hinzu */
}


.send-Transaction {
  max-width: 400px;
  margin: 0 auto;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.send-Transaction button {
  display: block; /* Ermöglicht das Zentrieren des Buttons */
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid transparent;
  background-color: #007bff;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.send-Transaction button:hover {
  background-color: #0056b3;
}

.send-Transaction button:disabled {
  background-color: #a6a6a6;
  cursor: not-allowed;
}

.connect-button {
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
  padding: 10px 20px;
  border: 2px solid transparent;
  border-radius: 5px;
  background-color: #09ce00;
  cursor: pointer;
  transition: all 0.3s ease;
  display: block; /* Ermöglicht das Zentrieren des Buttons */
  margin: 0 auto 20px; /* Zentriert den Button horizontal und fügt unten Abstand hinzu */
}

.connect-button:hover {
  background-color: #16ff00;
}

.connect-button:disabled {
  background-color: #a6a6a6;
  cursor: not-allowed;
}

.wallet-info {
  background-color: #f4f4f4;
  border-radius: 8px;
  padding: 20px;
  max-width: 400px;
  margin: 20px auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.wallet-info h4 {
  margin-bottom: 15px;
  font-size: 18px; /* Leicht größere Schriftgröße für Überschriften */
  border-bottom: 1px solid #e2e8f0; /* Eine feine Linie unter der Überschrift */
  padding-bottom: 5px; /* Abstand unter dem Text */
  text-transform: uppercase; /* Großbuchstaben wie beim Button */
}

.wallet-address, .wallet-balance, .none-indicator {
  background-color: #e8f0fe;
  padding: 10px;
  border-radius: 4px;
  margin: 10px 0; /* Fügt oben und unten einen Abstand hinzu */
  text-align: center; /* Zentriert den Text innerhalb des Elements */
}

.none-indicator {
  background-color: #ececec; /* Etwas hellerer Hintergrund für den 'none'-Zustand */
}
</style>


